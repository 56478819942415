import React, { Component } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { authenticationService } from "../../_services";

import { BreadCrumb } from "primereact/breadcrumb";
import { sectorService } from "../../_services/admin/sector.service";
export default class AddSectors extends Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: authenticationService.currentUserValue,
      sectorData: {}
    };
  }

  componentDidMount() {
    this.getSectorById();
  }

  getSectorById = () => {
    let sectorId = this.props.match.params.sectorId;
    sectorId &&
      sectorService.getSectorById(sectorId).then((data) => {
        if (data) {
          this.setState({ sectorData: data.Data });
        }
      });
  };

  render() {
    const items = [
      { label: "Management" },
      {
        label: "Add/Edit Sector"
      }
    ];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <div className="col-md-6">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    sectorName: this.state.sectorData.sectorName || "",
                    sectorId: this.state.sectorData._id || "",
                    currentUserId: this.state.currentUser.id
                  }}
                  validationSchema={Yup.object().shape({
                    sectorName: Yup.string().required("Sector Name is Required")
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    sectorService.addAndUpdateSector(formData).then((data) => {
                      if (data) {
                        setSubmitting(false);

                        swal("Sector Saved", " ", "success");
                        const { from } = this.props.location.state || {
                          from: { pathname: "/sectors" }
                        };
                        this.props.history.push(from);
                      }
                    });
                  }}
                >
                  {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleChange,
                    handleBlur
                  }) => {
                    return (
                      <Form>
                        <div className="form-group required">
                          <label className="control-label">
                            {this.state.sectorData._id
                              ? "Update Sector"
                              : "Add Sector"}
                          </label>
                          <Field
                            name="sectorName"
                            type="text"
                            placeholder="Sector Name"
                            className={
                              "form-control" +
                              (errors.sectorName && touched.sectorName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="sectorName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="offset-md-3 col-md-6 col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase pt-2"
                            >
                              {this.state.sectorData._id
                                ? "Update Sector"
                                : "Add Sector"}
                              {isSubmitting && (
                                <span>
                                  {" "}
                                  <i className="pi pi-spin pi-spinner"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
